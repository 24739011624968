.addinstructor label {
  font-size: small;
  margin-top: 9px;
  font-weight: 600;
}
.addinstructor input,.addinstructor textarea {
  font-size: small;
  margin-top: 4px;
  background-color: rgba(240, 240, 240, 1);
}
