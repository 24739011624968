.main-container {
  background-image: url("../../public/loginPageimg.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.loginbox-logo img {
  width: 20%;
  height: 40%;
  padding-top: 10vh;
}
.welcome-tag {
  width: 60%;
  margin: auto;
  padding-top: 5%;
  padding-left: 5px;
  font-size: large;
}
.welcome-tag span {
  margin-left: 15px;
}
.inputs label {
  font-size: small;
  padding-top: 2vh;
  font-family: "Montserrat";
  font-weight: bold;
}
.inputs input {
  font-size: small;
  margin-top: 1vh;
  padding: 2vh;
  min-height: 50px;
  font-family: "Montserrat";
}
.signin-btn button {
  margin-top: 2%;
  padding: 2.5vh;
  margin-bottom: 1vh;
  min-height: 50px;
}
@media (max-width: 575.98px) {
  .loginbox-logo img {
    width: 20%;
    height: 25%;
    padding-top: 5vh;
  }
  .inputs input {
    font-size: small;
    margin-top: 1vh;
    padding: 1.8vh;
    font-family: "Montserrat";
  }
  .signin-btn button {
    margin-top: 2%;
    padding: 1.7vh;
    margin-bottom: 1vh;
  }
  .welcome-tag {
    width: 80%;
    margin: auto;
    padding-top: 8%;
    padding-left: 7%;
    font-size: medium;
  }
  .welcome-tag span {
    margin-left: 8px;
  }

  .signin-btn button {
    margin-top: 4vh;
    padding: 2vh;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .loginbox-logo img {
    padding-top: 6vh;
  }
  .welcome-tag {
    width: 80%;
    margin: auto;
    padding-top: 4vh;
    padding-left: 8%;
    font-size: medium;
  }
  .welcome-tag span {
    margin-left: 8px;
  }
  .inputs label {
    padding-top: 5%;
  }

  .signin-btn button {
    margin-top: 7%;
    padding: 2vh;
  }
}
@media (min-width: 767px) and (max-width: 991.98px) {
  .loginbox-logo img {
    width: 17%;
    padding-top: 5vh;
  }
  .welcome-tag {
    width: 70%;
    margin: auto;
    padding-top: 5vh;
    padding-left: 8%;
    font-size: 100%;
  }
  .welcome-tag span {
    margin-left: 8px;
  }
  .inputs label {
    padding-top: 3%;
  }
  .signin-btn button {
    margin-top: 5vh;
    padding: 2vh;
  }
}

@media (min-width: 991px) and (max-width: 1200.98px) {
  .loginbox-logo img {
    width: 17%;
    padding-top: 5vh;
  }
  .welcome-tag {
    width: 72%;
    margin: auto;
    padding-top: 4vh;
    padding-left: 8%;
    font-size: larger;
  }
  .welcome-tag span {
    font-size: large;
    margin-left: 8px;
  }
  .inputs label {
    padding-top: 3%;
  }
  .signin-btn button {
    margin-top: 4.5vh;
    padding: 2vh;
  }
}

@media (min-width: 1200px) {
  .loginbox-logo img {
    padding-top: 5vh;
  }
  .welcome-tag {
    width: 70%;
    margin: auto;
    padding-top: 4vh;
    padding-left: 8%;
    font-size: larger;
  }
  .welcome-tag span {
    margin-left: 8px;
    font-size: large;
  }
  .inputs label {
    padding-top: 3vh;
  }
  .inputs input {
    padding-top: 2vh;
  }
  .signin-btn button {
    margin-top: 2.7vh;
    padding: 2vh;
  }
}
@media (min-height: 1450px) {
  .background-box {
    max-height: 50vh;
  }
}
