.DashboardContainer {
  display: grid;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  grid-template-columns: 20vw 80vw;
  background-color: rgba(252, 249, 255, 1);
  overflow: hidden;
  margin: auto;
  font-family: "Montserrat";
}
.subcontainer-1 {
  max-height: 100vh;
  height: 100%;
}

.subcontainer-2 {
  background-color: rgba(252, 249, 255, 1);
  width: 80vw;
  overflow: hidden;
}
.dashboardinfoCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.75rem;
  font-family: "Montserrat";
  padding: 0 12px;
}

@media screen and (max-width: 570px) {
  .DashboardContainer {
    display: flex;
    flex-direction: column;
  }
  .subcontainer-1 {
    top: 0;
    position: fixed;
    width: 100vw;
    max-height: 8vh;
  }
  .subcontainer-2 {
    width: 100vw;
    position: absolute;
    top: 50px;
    overflow: scroll;
  }
  .dashboardinfoCards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.25rem;
    column-gap: 0;
    padding: 0 42px;
  }
  .info-card {
    height: 80px;
  }
  .info-card p {
    font-size: small;
  }
}

@media screen and (min-width: 570px) and (max-width: 767.98px) {
  .DashboardContainer {
    grid-template-columns: 24vw auto;
  }
  .dashboardinfoCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    column-gap: 0;
    padding: 0 42px;
  }

  .info-card {
    width: 30vw;
    height: 100%;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .DashboardContainer {
    grid-template-columns: 23vw auto;
  }
  .dashboardinfoCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
  }

  .info-card {
    width: 35vw;
    height: 120px;
  }
}

@media (min-width: 991px) and (max-width: 1200.98px) {
  .subcontainer-2 {
    width: 80vw;
    overflow-x: hidden;
  }
  /* .dashboard_usertable Table Tbody {
    font-size: small;
  } */
}

@media (min-width: 1200px) {
  .subcontainer-2 {
    width: 80vw;
    overflow-x: hidden;
  }
  .subcontainer-2::-webkit-scrollbar {
    width: 0;
  }
}
