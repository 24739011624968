.pagination{
    list-style: none;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin:5px;
    font-size:0.85rem;
}
.pagination .page-num{
    cursor: pointer;
    font-weight: 400;
    padding: 6px 10px;
    border-radius: 10px;
}
.pagination .page-num:hover{
    background-color: #3F1B5B;
    color: white;
}
.pagination .page-num.active{
    background-color: #3F1B5B;
    color: white;
}