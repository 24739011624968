.menu-icon {
  display: none;
}
.mobileview {
  display: none;
}
.sidebar {
  height: 100%;
  width: 19vw;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  /* border-radius: 120px; */
  border-radius: 0 20px 20px 0;
  bottom: 0;
  z-index: 3;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebarContainer {
  border-radius: 0 24px 24px 0;
}

.sidebarlogo img {
  margin: 3vh auto;
  height: 7vw;
}
.eachbutton button {
  padding: 0 8px;
  width: 78%;

  min-width: 50px;
  margin: 2px 8px;
  color: white;
  /* z-index: 10; */
}
.eachbutton button:hover {
  background: #a19113;
}
.eachbutton.active button {
  background: #a19113;
}

.eachbutton img {
  padding-left: 1vw;
  height: 20px;
  opacity: 1;
}

.eachbutton p {
  margin-left: 1vw;
  opacity: 1;
  font-size: small;
}

.eachbutton button:hover img,
.eachbutton button:hover p {
  opacity: 1; /* Set opacity to 100% on button hover */
}
@media screen and (max-width: 570px) {
  .backgroundimages {
    display: none;
  }
  .sidebar {
    display: none;
  }

  .menu-icon {
    display: block;
    position: fixed;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 100 !important;
  }
  .mobileview {
    width: 70vw;
    height: 100vh;
    overflow-y: scroll;
    display: block;
    z-index: 100;
  }

  .mobileview .eachbutton {
    width: 100%;
    padding: 0 12px;
  }
  .mobileview .eachbutton p {
    padding-left: 3px;
    font-size: small;
  }
  .sidebarlogo img {
    margin: 3vh auto;
    z-index: 10;
    height: 110px;
  }
}

@media screen and (min-width: 570px) and (max-width: 767.98px) {
  .sidebar {
    width: 27vw;
  }
  .sidebarlogo img {
    margin: 3vh auto;
    z-index: 100;
    height: 13vw;
    min-height: 80px;
  }
  .eachbutton button {
    padding: 0 3px;
    width: 90%;
  }
  .eachbutton img {
    padding-left: 2px;
  }
  .eachbutton p {
    font-size: x-small;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .sidebar {
    width: 22vw;
  }
  .sidebarlogo img {
    margin: 3vh auto;
    height: 9vw;
    min-height: 80px;
  }
  .eachbutton button {
    padding: 0 3px;
    width: 89%;
  }
  .eachbutton img {
    padding-left: 2px;
  }
}
@media (min-width: 991px) and (max-width: 1200.98px) {
  .eachbutton button {
    padding: 0 7px;
    width: 85%;
  }
  .eachbutton img {
    padding-left: 8px;
    height: 20px;
    opacity: 1;
  }

  .eachbutton p {
    margin-left: 7px;
    opacity: 1;
    font-size: small;
  }
}
@media (min-width: 1200px) {
  .sidebarlogo img {
    height: 7vw;
    min-height: 90px;
  }
  .eachbutton img {
    padding-left: 1vw;
    height: 20px;
    opacity: 1;
  }

  .eachbutton p {
    margin-left: 1vw;
    opacity: 1;
    font-size: small;
  }
}
