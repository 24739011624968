.subscritpionTable {
  max-height: 55vh;
  overflow-y: scroll;
}
.subscritpionTable::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
@media (min-width: 727px) and (max-width: 866.98px) {
  .subscritpionTable p {
    font-size: 9px;
  }
}
