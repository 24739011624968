* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}
body {
  height: 100%;
  margin: auto;
  width: 100vw;
}
