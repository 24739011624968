.userdetail button{
    font-size: small;
    margin:0 6px ;
    
}
.userdetail .title{
    font-weight: 700;
}
.singledetailitem{
    padding-left: 28px;
    padding-top: 10px;
}
.singledetailitem label{
    font-weight: 600;
    font-size: medium;
}
.singledetailitem p{
    font-size: 14px;
}
.courses p{
    padding-left: 10px;
}
.scrollbar::-webkit-scrollbar{
    width: 0px;
}
@media (max-width: 767px)  {
    .userdetail button{
        font-size: x-small;
    }
    .singledetailitem label{
        font-size: 12px;
    }
    .singledetailitem p{
        font-size: 12px;
    }
}