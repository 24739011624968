.createCourse label {
  font-weight: 400;
  /* font-size: medium; */
  
}
.scrollbar::-webkit-scrollbar{
  width: 0px;
  height: 0px;
}
.createCourse input,.createCourse select {
  background-color: rgba(240, 240, 240, 1);
  font-size: small;
  margin-top: 8px;
}
.createCourserow {
  display: block;
  margin: auto;
  font-size: small;
}
.createCourserow > div {
  width: 95%;
  margin: auto;
  margin-top: 2%;
}

@media (min-width: 891px)  {
  .createCourserow {
    display: flex;
    margin: auto;
  }
  .createCourserow > div {
    width: 47%;
  }
}
